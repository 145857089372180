import React, { Component } from 'react'
import ReactTable from 'react-table'
import api from '../api'

import styled from 'styled-components'

import 'react-table/react-table.css'

const Wrapper = styled.div`
    padding: 0 40px 40px 40px;
`

// const Update = styled.div`
//     color: #ef9b0f;
//     cursor: pointer;
// `

// const Delete = styled.div`
//     color: #ff0000;
//     cursor: pointer;
// `

// class UpdateMovie extends Component {
//     updateUser = event => {
//         event.preventDefault()

//         window.location.href = `/movies/update/${this.props.id}`
//     }

//     render() {
//         return <Update onClick={this.updateUser}>Update</Update>
//     }
// }
 
// class DeleteMovie extends Component {
//     deleteUser = event => {
//         event.preventDefault()

//         if (
//             window.confirm(
//                 `Do tou want to delete the movie ${this.props.id} permanently?`,
//             )
//         ) {
//             api.deleteMovieById(this.props.id)
//             window.location.reload()
//         }
//     }

//     render() {
//         return <Delete onClick={this.deleteUser}>Delete</Delete>
//     }
// }


class MoviesList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            movies: [],
            columns: [],
            isLoading: false,
        }
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true })

        await api.list_All_WG_Ships().then(movies => {
            this.setState({
                movies: movies.data.data,
                isLoading: false,
            })
            console.log("this.state.movies:", this.state.movies)
            
        })
    }
 
   
    render() {
        const { movies, isLoading } = this.state

        const columns = [
            {
                Header: 'Ship ID',
                accessor: 'ship_id',
                filterable: true,
            },
            {
                Header: 'Ship Name',
                accessor: 'name',
                filterable: true,
            },
            {
                Header: 'Tier',
                accessor: 'tier',
                filterable: true,
            },
            {
                Header: 'Nation',
                accessor: 'nation',
                filterable: true,
                //Cell: props => <span>{props.value[0].toUpperCase().concat(props.value.substring(1))}</span>,
                //Cell: props => <span>{formatCountryCode(props.value)}</span>,
            },
            {
                Header: 'Type',
                accessor: 'type',
                filterable: true,
                //Cell: props => <span>{formatShipType(props.value)}</span>,
            },
            // {
            //     Header: 'Time',
            //     accessor: 'time',
            //     Cell: props => <span>{props.value.join(' / ')}</span>,
            // },
            // {
            //     Header: '',
            //     accessor: '',
            //     Cell: function(props) {
            //         return (
            //             <span>
            //                 <DeleteMovie id={props.original._id} />
            //             </span>
            //         )
            //     },
            // },
            // {
            //     Header: '',
            //     accessor: '',
            //     Cell: function(props) {
            //         return (
            //             <span>
            //                 <UpdateMovie id={props.original._id} />
            //             </span>
            //         )
            //     },
            // },
        ]

        let showTable = true
        if (!movies.length) {
            showTable = false
        }

        return (
            <Wrapper>
                {!showTable &&<h1>Pulling data from DB...</h1>}
                {showTable &&<h1>Data is below:</h1>}
                {showTable && (
                    <ReactTable
                        data={movies}
                        columns={columns}
                        loading={isLoading}
                        defaultPageSize={10}
                        showPageSizeOptions={true}
                        minRows={0}
                    />
                )}
            </Wrapper>
        )
    }
}

export default MoviesList
