import axios from 'axios'

//TRY THIS TO PUT A TIME STAMP IN ALL CONSOLE LOGS
const origLog = console.log;

console.log = function () {
  const args = Array.from(arguments);
  args.unshift(new Date().toISOString().replace('T', ' ').slice(0, 23));
  origLog.apply(console, args);
};
//TRY THIS TO PUT A TIME STAMP IN ALL CONSOLE LOGS

console.log("process.env.REACT_APP_CB_TEAM_COMPOSER_BASEURL : ", process.env.REACT_APP_CB_TEAM_COMPOSER_BASEURL)
var api = ""
if(process.env.REACT_APP_CB_TEAM_COMPOSER_BASEURL === "http://localhost:3001/api"){
    console.log("STARTING CLIENT WITHOUT CREDENTIALS : process.env.REACT_APP_CB_TEAM_COMPOSER_BASEURL = ", process.env.REACT_APP_CB_TEAM_COMPOSER_BASEURL)
    api = axios.create({
        // MUST COMMENT OUT withCredentials: true IF WORKING WITH LOCALHOST ON HTTP
        baseURL: process.env.REACT_APP_CB_TEAM_COMPOSER_BASEURL,
    })
}else{
    console.log("STARTING CLIENT WITH CREDENTIALS : process.env.REACT_APP_CB_TEAM_COMPOSER_BASEURL = ", process.env.REACT_APP_CB_TEAM_COMPOSER_BASEURL)
    api = axios.create({
        // MUST COMMENT OUT withCredentials: true IF WORKING WITH LOCALHOST ON HTTP
        baseURL: process.env.REACT_APP_CB_TEAM_COMPOSER_BASEURL,
        withCredentials: true
    })
}

export const add_player_names_to_available_list = payload => api.put(`/WOWs/Add_Player_Names_To_Available_List`, payload)
export const searchPlayerNames = payload => api.put(`/WOWs/SearchPlayerNames`, payload)
export const searchClanNames = payload => api.put(`/WOWs/SearchClanNames`, payload)
export const getPlayerShipStats = payload => api.put(`/WOWs/GetPlayerShipStats`, payload)
export const recordHitDB = payload => api.put(`/WOWs/RecordHitDB`, payload)

export const grant_access_return = payload => api.put(`/WOWs/Grant_Access_Return`, payload)
export const get_All_WG_Ships = payload => api.post(`/WOWs/Get_All_WG_Ships`, payload)
export const list_All_WG_Ships = () => api.get(`/WOWs/List_All_WG_Ships`)
export const get_All_WG_Ship_Expected_Values = () => api.get(`/WOWs/Get_All_WG_Ship_Expected_Values`)

const apis = {
    add_player_names_to_available_list,
    searchPlayerNames,
    searchClanNames,
    getPlayerShipStats,
    recordHitDB,
    grant_access_return,
    get_All_WG_Ships,
    list_All_WG_Ships,
    get_All_WG_Ship_Expected_Values,
}

export default apis
