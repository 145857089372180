import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import logo from '../nocapeu.png';

const Wrapper = styled.a.attrs({
  className: 'navbar-brand',
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 10%;
  img {
    margin-right: 10px;
  }
`;

const Container = styled.div.attrs({
  className: 'container',
})`
  /* height: 150px; */
`;

const Nav = styled.nav.attrs({
  className: 'navbar navbar-expand-lg navbar-dark bg-dark',
})`
  /* margin-bottom: 20 px; */
`;

const LinkTextCenter = styled.div`
  text-align: center;
  flex-basis: 80%;
  white-space: pre-wrap;
  a {
    color: limegreen;
    text-decoration: none;
    &:hover {
      color: yellow;
      text-decoration: none;
    }
  }
`;


const LinkTextRight = styled(Link)`
  text-align: right;
  flex-basis: 10%;
`;

const NavBar = () => {
  
  const [whatIsThisLink, setWhatIsThisLink] = useState('What is this?');
  const [centerTitle, setCenterTitle] = useState('CLAN BATTLES TEAM COMPOSER');

  const handleInstructions = () => {
    if (whatIsThisLink === 'What is this?') {
      
      setCenterTitle(
        <LinkTextCenter style={{ color: 'white', fontSize: '16px',  textAlign: 'center', flexBasis: '80%' }}>
          <p>
          Tired of "what ship do you have," "are you good in it," and similar questions? Night after night, CB season after CB season? So was I, no more. 
          Select your players, pick your comp, and a table is generated based on your settings, representing the highest combined PR possible. Guess less, play more.
          </p>
          <p>
          How does it work? The server pulls all players' stats for all ships from WG (hidden profiles are not considered), 
          then uses the <a href="https://en.wikipedia.org/wiki/Hungarian_algorithm" target="_blank" rel="noopener noreferrer">Hungarian algorithm </a> 
          to calculate the maximum total combined PR player-to-ship assignments, while guaranteeing all ships are assigned if at all possible.
          </p>
          <p>
          At this time, WG allows a maximum of 10 API calls per second. Stats for up to 100 ships for each player can be pulled per API call. 
          There are 702 ships in the game as of June 2023. When adding all clan players, that's up to 50 players * 8 calls / 10 per second = 40 seconds 
          to send all API calls. WG takes their time replying so it takes 2-3 minutes to populate the "Available Players" list. Add S&H and taxes to that. 
          The stats data is saved into the local DB for 24 hours so subsequent pulls should be faster.
          </p>
        </LinkTextCenter>
      );
      setWhatIsThisLink('I wanna talk to your manager!');
    } else if (whatIsThisLink === 'I wanna talk to your manager!') {
      setCenterTitle(
        <LinkTextCenter style={{ color: 'white', fontSize: '16px',  textAlign: 'center', flexBasis: '80%' }}>
          <p>
            DM Bozo128 on NOCAP discord : <a href="https://discord.gg/7xV8nQz8" target="_blank" rel="noopener noreferrer">https://discord.gg/7xV8nQz8</a>
          </p>
          <p>
            If reporting an issue make sure to provide a screenshot and how to reproduce it.
            </p>
            <p>
            Suggestions are welcome. Want to help? Can you style this page in dark theme? DM me now, untold riches await.

          </p>
        </LinkTextCenter>

      )

      
      setWhatIsThisLink('All righty then');
    } else if (whatIsThisLink === 'All righty then') {
      
      setWhatIsThisLink('What is this?');
      setCenterTitle('CLAN BATTLES TEAM COMPOSER');
    }
  };
  

  return (
    <Container>
      <Nav>
        <Wrapper href="/">
          <img src={logo} width="50" height="50" alt="Logo" />
        </Wrapper>
        <LinkTextCenter className="navbar-brand">
          {centerTitle}
        </LinkTextCenter>
        <LinkTextRight className="navbar-brand" onClick={handleInstructions}>
          {whatIsThisLink}
        </LinkTextRight>
      </Nav>
    </Container>
  );
};

export default NavBar;






















      {/* <Nav>
        <List>
          <Item>
            <NavLink to="/WOWs/Grant_Access" className="nav-link">
              Grant Access
            </NavLink>
          </Item>
          <Item>
            <NavLink to="/WOWs/Get_All_WG_Ships" className="nav-link">
              Get All WG Ships
            </NavLink>
          </Item>
          <Item>
            <NavLink to="/WOWs/List_All_WG_Ships" className="nav-link">
              List All WG Ships
            </NavLink>
          </Item>
          <Item>
            <NavLink to="/WOWs/CB_Team_Composer" className="nav-link">
              CB Team Composer
            </NavLink>
          </Item>
        </List>
      </Nav> */}

















// import React, { Component } from 'react'
// import styled from 'styled-components'
// import { Link, NavLink } from 'react-router-dom'
// //import { NavLink } from 'react-router-dom'
// //import Links from './Links'
// //import HeaderWithLogo from './HeaderWithLogo'

// import logo from '../nocapeu.png'

// const Wrapper = styled.a.attrs({
//     className: 'navbar-brand',
//   })`
//     display: flex;
//     flex: 1;
//     justify-content: center;
//     align-items: center;
//   `;

// const Container = styled.div.attrs({
//     className: 'container',
// })`
//     /* height: 150px; */
// `

// const Nav = styled.nav.attrs({
//     className: 'navbar navbar-expand-lg navbar-dark bg-dark',
// })`
//     /* margin-bottom: 20 px; */
// `

// const Collapse = styled.div.attrs({
//     className: 'collapse navbar-collapse',
// })``

// const List = styled.div.attrs({
//     className: 'navbar-nav mr-auto',
// })``

// const Item = styled.div.attrs({
//     className: 'collapse navbar-collapse',
// })``

// const LinkText = styled(Link)`
// text-align: center;
// `;
  
//   class NavBar extends Component {
//     render() {
//       return (
//         <Container>
//           <Nav>
//             <Wrapper href="https://discord.gg/nocapgaming">
//               <img src={logo} width="50" height="50" alt="discord.gg/nocapgaming" />
//             </Wrapper>
//               <React.Fragment>
//               <Collapse>
//                 <List>
//                   <Item>
//                     <LinkText to="/WOWs/CB_Team_Composer" className="navbar-brand">
//                       N0KAP AND OTHER CAP AVERSE ASSOCIATES
//                     </LinkText>
//                   </Item>   
//                 </List>
//               </Collapse>
//             </React.Fragment>
//           </Nav>
//           <Nav>
//             <React.Fragment>
//               <Collapse>
//                 <List>
//                   <Item>
//                     <NavLink to="/WOWs/Grant_Access" className="nav-link">
//                       Grant Access
//                     </NavLink>
//                   </Item>
//                   <Item>
//                     <NavLink to="/WOWs/Get_All_WG_Ships" className="nav-link">
//                       Get All WG Ships
//                     </NavLink>
//                   </Item>
//                   <Item>
//                     <NavLink to="/WOWs/List_All_WG_Ships" className="nav-link">
//                       List All WG Ships
//                     </NavLink>
//                   </Item>
//                   <Item>
//                     <NavLink to="/WOWs/Clan_Roster" className="nav-link">
//                       Clan Roster
//                     </NavLink>
//                   </Item>
//                   <Item>
//                     <NavLink to="/WOWs/CB_Team_Composer" className="nav-link">
//                       CB Team Composer
//                     </NavLink>
//                   </Item>
//                 </List>
//               </Collapse>
//             </React.Fragment>
//           </Nav>
//         </Container>
//       )
//     }
//   }
  

// export default NavBar
