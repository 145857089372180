import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import api from '../api';
import styled from 'styled-components';
import munkres from 'munkres-js';

// var React = require('react');
// var useEffect = React.useEffect;
// var useState = React.useState;
// var ReactTable = require('react-table');
// require('react-table/react-table.css');
// var api = require('../api');
// var styled = require('styled-components').default;
// var munkres = require('munkres-js');

// const SelectWrapper = styled.div`
//   /* position: relative; */
//   /* width: 200px; */
//   /* margin: 15px 0px 0px 5px; */
// `;

const Select = styled.select`
  /* width: 100%; */
  /* height: 100%; */
  padding: 0.375rem 0.75rem;
  margin: 15px 0px 0px 5px;
  /* font-size: 16px; */
  border: 1px solid #ced4da;
  border-radius: 4px;
  /* background-color: #fff;
  color: #333; */
  appearance: none;
  cursor: pointer;
`;

const Option = styled.option`
  background-color: ${(props) => (props.isEven ? "#EEEEEE" : "#DDDDDD")};
  color: #333;

  &:hover {
    background-color: ${(props) => (props.isEven ? "#EEEEEE" : "#DDDDDD")};
  }
`;



const Wrapper = styled.div.attrs({
  className: "form-group",
})`
  margin: 0 30px;
  font-weight: bold;

`;


const customPopup = (message) => {
  const popup = document.createElement('div');
  popup.style.position = 'fixed';
  popup.style.top = '50%';
  popup.style.left = '50%';
  popup.style.transform = 'translate(-50%, -50%)';
  popup.style.backgroundColor = '#333';
  popup.style.color = '#fff';
  popup.style.padding = '20px';
  popup.style.borderRadius = '5px';
  popup.style.fontSize = '18px';
  popup.style.textAlign = 'center';
  popup.innerHTML = message;
  document.body.appendChild(popup);

  setTimeout(() => {
    document.body.removeChild(popup);
    displayNextMessage();
  }, 2000);
}

const Button = styled.a.attrs(({ active }) => ({
  className: active ? 'btn btn-primary' : 'btn btn-info',
}))`
    margin: 15px 0px 0px 5px;
    
    &.btn-primary{
      
    }
    &.btn-info{
      background-color: lightgray;
      color: #212529;
    }
`

const ExtraRightMarginButton = styled(Button)`
  margin-right: 30px;
`;


const ExtraLeftMarginButton = styled(Button)`
  margin-left: 30px;
`;


const CancelButton = styled.a.attrs({
    className: `btn btn-danger`,
})`
    margin: 15px 0px 0px 5px;
`

const DualListContainer = styled.div.attrs({

})`
  display: flex;
`

const Label = styled.label`
  margin: 15px 0px 0px 5px;
  display: inline-block;
  text-align: center;
  width: 250px;
  
`;

const List = styled.ul`
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 1px solid #ced4da;
  overflow: auto;
  width: 250px;
  height: 250px;
  text-align: center;
  vertical-align: middle;
  margin: 15px 0px 0px 5px;
  li {
    &:hover {
      background: #CCCCCC;
    }
    &:active,
    &:focus {
      background: #CCCCCC;
    }
  }
`;


const InputText = styled.input.attrs({
  
})`
  margin: 15px 0px 0px 5px;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
`;

const ListForClanAndPlayerSearch = styled.ul`
  
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
  height: 200px;
  left: 0;
  list-style: none;
  margin: 15px 15px 15px 5px;
  margin: 0;
  overflow: auto;
  overflow-y: scroll;
  padding: 0;
  position: absolute;
  text-align: left;
  top: calc(100% + 5px);
  width: 100%;
  z-index: 1;
  
  li {
    &:hover {
      background: #CCCCCC;
    }
    &:active,
    &:focus {
      background: #CCCCCC;
    }
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  
`;


const ListItem = styled.li`
  background: ${(props) => (props.highlighted ? "#EEEEEE" : "#DDDDDD")};
  margin: 0px 5px 0px 5px;
  
`;


const SearchShipsInputBox = styled.input.attrs({
  className: 'test',
})`
  margin: 9px -6px -6px 5px;
  width: 250px;
  display: inline-block;
  padding: 5px 5px 5px 5px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;

`;

const regionDropDownSelectorOptions = ["NA", "EU", "ASIA"];
const messageQueue = [];

const displayNextMessage = () => {
  const nextMessage = messageQueue.shift();
  if (nextMessage) {
    customPopup(nextMessage);
  }
};

const WOWs_CB_Team_Composer = () => {

  // function sortListAndRemoveDuplicates(list) {
  //   // Create a set from the list to remove duplicates
  //   const set = new Set(list);
  //   // Convert the set back to an array
  //   const array = [...set];
  //   // Sort the array
  //   return sortList(array)


  // }

  // function sortList(list) {
  //   // Sort the array
  //   return list.sort((a, b) => {
  //     return a.localeCompare(b, undefined, { sensitivity: 'accent', ignorePunctuation: true });
  //   });
  // }

  function sortListAndRemoveDuplicateObjects(list, prop) {
    // console.log("sortListAndRemoveDuplicateObjects LIST: ", list)
    // console.log("sortListAndRemoveDuplicateObjects PROP: ", prop)

    // Create a set from the list to remove duplicates
    const set = new Set(list);
    // Convert the set back to an array
    const array = [...set];
    // Sort the array
    return array.sort((a, b) => {
      return a[prop].localeCompare(b[prop], undefined, { sensitivity: 'accent', ignorePunctuation: true });
    });

  }

  function sortListObjects(list, prop) {
    return list.sort((a, b) => {
      return a[prop].localeCompare(b[prop], undefined, { sensitivity: 'accent', ignorePunctuation: true });
    });
  }

  // const fullListOfNations = (["Commonwealth","Europe","netherlands","Italy","USA","Pan Asia","France","USSR","Germany","UK","Japan","spain","Pan America"])


  // Initialize state variables
  //THESE HOLD PLAYER LISTS LEFT AND RIGHT
  const [leftList, setLeftList] = useState([{nickname: "Add Clans or Players"}]);
  const [rightList, setRightList] = useState([{nickname : "None Yet"}]);

  const [isUserListDataLoading, setIsUserListDataLoading] = useState(false);



  //THESE HOLD SHIP LISTS LEFT AND RIGHT
  const [leftShipList, setLeftShipList] = useState([{name : "Pick Ships"}]); //SHIP LIST ALL WG SHIPS
  const [rightShipList, setRightShipList] = useState([{Header: "Add Comp Ships"}]);
   
  //FULL SHIP LIST IS PULLED INTO fullShipList, THIS IS USED TO FILTER SHIP LEFT LIST
  const [fullShipList, setFullShipList] = useState([]);

  //BUTTONS FOR CLAN / PLAYER SEARCH
  const [clanSearchButton, setClanSearchButton] = useState(false);
  const [playerSearchButton, setPlayerSearchButton] = useState(false);

  //SEARCH BOX STATE
  const [isInputTextDisabled, setIsInputTextDisabled] = useState(true);
  const [clanOrPlayerSearchBoxValue, setClanOrPlayerSearchBoxValue] = useState("Pick Clan or Player Search button");

  //MIN GAMES REQUIRED TO CALCULATE PR, DEFAULT 50
  const [minGamesToQualifyForPRCalculation, setMinGamesToQualifyForPRCalculation] = useState("Minimum Games: 50");
  
  //MODES SELECTION FOR PR CALCULATION
  const [pvpModeAllRandom, setPvpModeAllRandom] = useState(true);
  const [pvpModeSolo, setPvpModeSolo] = useState(false);
  const [pvpModeDiv2, setPvpModeDiv2] = useState(false);
  const [pvpModeDiv3, setPvpModeDiv3] = useState(false);
  //FLAG TO USE WITH USEEFFECT TO RE-CALCULATE TABLE ANY TIME MODES ARE CHANGED
  const [pvpModeChanged, setPvpModeChanged] = useState(true);

  const [rankModeAllRank, setRankModeAllRank] = useState(true);
  const [rankModeSolo, setRankModeSolo] = useState(false);
  const [rankModeDiv2, setRankModeDiv2] = useState(false);
  const [rankModeDiv3, setRankModeDiv3] = useState(false);
  //FLAG TO USE WITH USEEFFECT TO RE-CALCULATE TABLE ANY TIME MODES ARE CHANGED
  const [rankModeChanged, setRankModeChanged] = useState(true);


  //REGION PICKER DROP DOWN
  const [regionDropDownSelectorValue, setRegionDropDownSelectorValue] = useState("NA");


  
  //CLANS OR PLAYERS SEARCH RESULTS GO HERE
  const [dropdownValues, setDropdownValues] = useState([]);
  
  const [isListOpen, setIsListOpen] = useState(false);

  //ALL PLAYER SHIP STATS ARE HERE - PLAYER ACCOUNT ID, PLAYER ACCOUNT NICKNAME, SHIP NICKNAMES, SHIP ID, 
  const [allPlayerStats, setAllPlayerStats] = useState([]);

  
  const [allShipExpectedValues, setAllShipExpectedValues] = useState([]);

  // WHEN TO RENDER TABLE
  const [showTable, setShowTable] = useState(false);

  //ACTIVITY BOX
  const [showActivity, setShowActivity] = useState("");

  //THESE 3 HOLD SHIP FILTER INFO, WE USE THEM TO FILTER SHIP LIST ON ANY CHANGE IN FILTERS
  // const [nationShipList, setNationShipList] = useState([""]);
  // const [tierShipList, setTierShipList] = useState([]);
  // const [typeShipList, setTypeShipList] = useState([]);
  
  const [showPopup, setShowPopup] = useState(false);
  
  const [shipListSearchTerm, setShipListSearchTerm] = useState('Search / Filter Ships');
  
  //KEEPS ROWS / PR DATA FOR THE TABLE
  const [allPlayers, setallPlayers] = useState([]);
  const [columns, setColumns] = useState([])

  // FUNCTION TO PULL CLAN DATA
  const handlePullWGShipList = async () => {
    // console.log("***WOWs_CB_Team_Composer : handlePullWGShipList");

    const res = await api.list_All_WG_Ships();
    // console.log("RES : ", res);
    // console.log("RES.data.data:", res.data.data);
    //THIS IS FOR SHIPLEFTLIST AND IT'S NOT ACTED UPON UNTIL 
        //MOVED TO RIGHTSHIPLIST AT WHICH TIME USEEFFECT KICKS IN
        const data = res.data.data.map(item => ({
          Header: item.name,
          // name: item.name,
          accessor: item.ship_id.toString(),
          ship_id: item.ship_id,
          // _id: item._id,
          
        }));
        
        // console.log("handlePullWGShipList data:", data);
        return(data)


    // try {
    //     const res = await api.list_All_WG_Ships();
    //     console.log("RES : ", res);
    //     console.log("RES.data.data:", res.data.data);

    //     //THIS IS FOR SHIPLEFTLIST AND IT'S NOT ACTED UPON UNTIL 
    //     //MOVED TO RIGHTSHIPLIST AT WHICH TIME USEEFFECT KICKS IN
    //     const data = res.data.data.map(item => ({
    //       Header: item.name,
    //       // name: item.name,
    //       accessor: item.ship_id.toString(),
    //       ship_id: item.ship_id,
    //       // _id: item._id,
          
    //     }));
        
    //     // console.log("handlePullWGShipList data:", data);
    //     return(data)

    // } catch (error) {
    //     // console.log(error);
    // }
  }

  const handlePullWGShipExpectedValues = async () => {
    // console.log("***WOWs_CB_Team_Composer : handlePullWGShipExpectedValues");

    try {
        const res = await api.get_All_WG_Ship_Expected_Values();
        // console.log("handlePullWGShipExpectedValues RES.data.data:", res.data.data);
       
        return(res.data.data)

    } catch (error) {
        // console.log(error);
    }
  }


  //MIN GAMES BOX
  const handleChangeMinGamesToQualifyForCalculation = async (event) => {
    // console.log("***minGamesToQualifyForCalculation BOX CLICKED :event.target.value: ", event.target.value)
    setMinGamesToQualifyForPRCalculation(event.target.value); //KEEP VALUE AS WE TYPE 
  }  

  const handleClickMinGamesToQualifyForCalculation = (event) => {
    // console.log("handleClickMinGamesToQualifyForCalculation CLICKED : SETTING setMinGamesToQualifyForPRCalculation TO 'Minimum Games: '")
    setMinGamesToQualifyForPRCalculation("Minimum Games: ");
  };

  const handleOnBlurMinGamesToQualifyForCalculation = async (event) => {
    // console.log(event.target.value)
    if(event.target.value === "Minimum Games: "){
      setMinGamesToQualifyForPRCalculation("Minimum Games: 50");
    }
    setTimeout(() => setIsListOpen(false), 100);
  }


//******************************************************************************** PVP MODE ******************************************************************************* */
//MODE SELECTION BUTTONS, STATE HANDLING
const clickPvpModeAllRandom = () => {
  if(pvpModeAllRandom){
    //SET IT TO FALSE AND SET OTHERS AS NEEDED
    setPvpModeAllRandom(false)
    setPvpModeSolo(false)
    setPvpModeDiv2(false)
    setPvpModeDiv3(false)
  }else{
    //SET IT TO TRUE AND SET OTHERS AS NEEDED
    setPvpModeAllRandom(true)
    setPvpModeSolo(false)
    setPvpModeDiv2(false)
    setPvpModeDiv3(false)
  }
  //LASTLY, TOUCH THE FLAG TO FORCE TABLE RECALCULATION
  setPvpModeChanged(!pvpModeChanged)

}

const clickPvpModeSolo = () => {
  setPvpModeSolo(!pvpModeSolo)
  if(pvpModeSolo && !pvpModeDiv2 && !pvpModeDiv3){
    //ALL 3 DISABLED, ENABLE ALL RANDOMS
    setPvpModeAllRandom(true)
  }else if(!pvpModeSolo && pvpModeDiv2 && pvpModeDiv3){
    //ALL 3 ENABLED, SET ALL RANDOM TO TRUE AND DISABLE THE REST
    setPvpModeAllRandom(true)
    setPvpModeSolo(false)
    setPvpModeDiv2(false)
    setPvpModeDiv3(false)
  }else{
    setPvpModeAllRandom(false)
  }
  //LASTLY, TOUCH THE FLAG TO FORCE TABLE RECALCULATION
  setPvpModeChanged(!pvpModeChanged)

}
const clickPvpModeDiv2 = () => {
  setPvpModeDiv2(!pvpModeDiv2)
  if(!pvpModeSolo && pvpModeDiv2 && !pvpModeDiv3){
    //ALL 3 DISABLED, ENABLE ALL RANDOMS
    setPvpModeAllRandom(true)
  }else if(pvpModeSolo && !pvpModeDiv2 && pvpModeDiv3){
    //ALL 3 ENABLED, SET ALL RANDOM TO TRUE AND DISABLE THE REST
    setPvpModeAllRandom(true)
    setPvpModeSolo(false)
    setPvpModeDiv2(false)
    setPvpModeDiv3(false)
  }else{
    setPvpModeAllRandom(false)
  }
  //LASTLY, TOUCH THE FLAG TO FORCE TABLE RECALCULATION
  setPvpModeChanged(!pvpModeChanged)

}
const clickPvpModeDiv3 = () => {
  setPvpModeDiv3(!pvpModeDiv3)
  if(!pvpModeSolo && !pvpModeDiv2 && pvpModeDiv3){
    //ALL 3 DISABLED, ENABLE ALL RANDOMS
    setPvpModeAllRandom(true)
  }else if(pvpModeSolo && pvpModeDiv2 && !pvpModeDiv3){
    //ALL 3 ENABLED, SET ALL RANDOM TO TRUE AND DISABLE THE REST
    setPvpModeAllRandom(true)
    setPvpModeSolo(false)
    setPvpModeDiv2(false)
    setPvpModeDiv3(false)
  }else{
    setPvpModeAllRandom(false)
  }
  //LASTLY, TOUCH THE FLAG TO FORCE TABLE RECALCULATION
  setPvpModeChanged(!pvpModeChanged)
}

//******************************************************************************** PVP MODE ******************************************************************************* */
//******************************************************************************** RANK MODE ******************************************************************************* */

//MODE SELECTION BUTTONS, STATE HANDLING
const clickRankModeAllRank = () => {
  if(rankModeAllRank){
    //SET IT TO FALSE AND SET OTHERS AS NEEDED
    setRankModeAllRank(false)
    setRankModeSolo(false)
    setRankModeDiv2(false)
    setRankModeDiv3(false)
  }else{
    //SET IT TO TRUE AND SET OTHERS AS NEEDED
    setRankModeAllRank(true)
    setRankModeSolo(false)
    setRankModeDiv2(false)
    setRankModeDiv3(false)
  }
  //LASTLY, TOUCH THE FLAG TO FORCE TABLE RECALCULATION
  setRankModeChanged(!rankModeChanged)

}

const clickRankModeSolo = () => {
  setRankModeSolo(!rankModeSolo)
  if(rankModeSolo && !rankModeDiv2 && !rankModeDiv3){
    //ALL 3 DISABLED, ENABLE ALL RANDOMS
    setRankModeAllRank(true)
  }else if(!rankModeSolo && rankModeDiv2 && rankModeDiv3){
    //ALL 3 ENABLED, SET ALL RANDOM TO TRUE AND DISABLE THE REST
    setRankModeAllRank(true)
    setRankModeSolo(false)
    setRankModeDiv2(false)
    setRankModeDiv3(false)
  }else{
    setRankModeAllRank(false)
  }
  //LASTLY, TOUCH THE FLAG TO FORCE TABLE RECALCULATION
  setRankModeChanged(!rankModeChanged)

}
const clickRankModeDiv2 = () => {
  setRankModeDiv2(!rankModeDiv2)
  if(!rankModeSolo && rankModeDiv2 && !rankModeDiv3){
    //ALL 3 DISABLED, ENABLE ALL RANDOMS
    setRankModeAllRank(true)
  }else if(rankModeSolo && !rankModeDiv2 && rankModeDiv3){
    //ALL 3 ENABLED, SET ALL RANDOM TO TRUE AND DISABLE THE REST
    setRankModeAllRank(true)
    setRankModeSolo(false)
    setRankModeDiv2(false)
    setRankModeDiv3(false)
  }else{
    setRankModeAllRank(false)
  }
  //LASTLY, TOUCH THE FLAG TO FORCE TABLE RECALCULATION
  setRankModeChanged(!rankModeChanged)

}
const clickRankModeDiv3 = () => {
  setRankModeDiv3(!rankModeDiv3)
  if(!rankModeSolo && !rankModeDiv2 && rankModeDiv3){
    //ALL 3 DISABLED, ENABLE ALL RANDOMS
    setRankModeAllRank(true)
  }else if(rankModeSolo && rankModeDiv2 && !rankModeDiv3){
    //ALL 3 ENABLED, SET ALL RANDOM TO TRUE AND DISABLE THE REST
    setRankModeAllRank(true)
    setRankModeSolo(false)
    setRankModeDiv2(false)
    setRankModeDiv3(false)
  }else{
    setRankModeAllRank(false)
  }
  //LASTLY, TOUCH THE FLAG TO FORCE TABLE RECALCULATION
  setRankModeChanged(!rankModeChanged)
}



//******************************************************************************** RANK MODE ******************************************************************************* */



const handleChangeSearchClanOrPlayerName = async (event) => {

  // console.log("***handleChangeSearchClanOrPlayerName CLICKED: SEARCH BOX VALUE :event.target.value: ", event.target.value)

  setClanOrPlayerSearchBoxValue(event.target.value); //KEEP VALUE AS WE TYPE 
  //HERE WE HAVE SEARCH VALUE
  
  const currentClanSearchButton = clanSearchButton
  const currentPlayerSearchButton = playerSearchButton

  try{
    if(currentClanSearchButton === true && event.target.value.length > 1){
      //CASE CLAN SEARCH
      // console.log("CLAN SEARCH")

      const res = await api.searchClanNames({ clanName: event.target.value, region: regionDropDownSelectorValue });
      // console.log("handleChangeSearchClanOrPlayerName : CLAN SEARCH : RES.data.data : ", res.data.data)
      setDropdownValues(res.data.data);
      setIsListOpen(true);
      

    }else if(currentPlayerSearchButton === true && event.target.value.length > 2){
      //CASE PLAYER SEARCH
      console.log("PLAYER SEARCH")
      const res = await api.searchPlayerNames({ playerName: event.target.value, region: regionDropDownSelectorValue });
      // console.log("handleChangeSearchClanOrPlayerName : PLAYER SEARCH : RES.data.data : ", res.data.data)
      setDropdownValues(res.data.data);
      setIsListOpen(true);

    }else{
      // console.log("DOING NOTHING")
    }   

  } catch (error) {
    // console.log(error);
  }
}

const handleChange_ShipList = (event) => {
  let filteredList = fullShipList.filter(item => 
    item.Header.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").startsWith(event.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
  filteredList = filteredList.concat(fullShipList.filter(item => !filteredList.includes(item) && item.Header.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(event.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))));
  setLeftShipList(filteredList)
  setShipListSearchTerm(event.target.value)
}

const handleRestoreFullShipList = (event) => {
  setShipListSearchTerm('')
  setLeftShipList(fullShipList)
}

const handleClickSearchClanOrPlayerName = (event) => {
  // console.log("handleClickSearchClanOrPlayerName CLICKED : SETTING clanOrPlayerSearchBoxValue TO ''")
  setClanOrPlayerSearchBoxValue("");

};

const handleOnBlurSearchClanOrPlayerName = (event) => {
  setTimeout(() => setIsListOpen(false), 100);
  setClanOrPlayerSearchBoxValue("Pick Clan or Player Search button")

};

const handleChangeRegionDropDownSelector = (event) => {
  console.log("REGION SELECTED: event.target.value:", event.target.value)
  setRegionDropDownSelectorValue(event.target.value)
  console.log("RegionDropDownSelectorValue:", regionDropDownSelectorValue)

};


const clearAllSelections = () => {
  setLeftList([{nickname: "Add Clans or Players"}])
  setRightList([{nickname : "None Yet"}])
  setRightShipList([{Header: "Add Comp Ships"}])

  setPvpModeAllRandom(true)
  setPvpModeSolo(false)
  setPvpModeDiv2(false)
  setPvpModeDiv3(false)

  setRankModeAllRank(true)
  setRankModeSolo(false)
  setRankModeDiv2(false)
  setRankModeDiv3(false)
  

  setMinGamesToQualifyForPRCalculation("Minimum Games: 50")
  setIsInputTextDisabled(true);
  setClanOrPlayerSearchBoxValue("Pick Clan or Player Search button");
  setClanSearchButton(false);
  setPlayerSearchButton(false);
  setShipListSearchTerm('Search Ships')
  setLeftShipList(fullShipList)

}

const clickClanSearchButton = () => {
  //ENABLE INPUT FIELD
  if(isInputTextDisabled){setIsInputTextDisabled(false)}
  setClanOrPlayerSearchBoxValue("Enter Clan Name")
  setClanSearchButton(true)
  setPlayerSearchButton(false)
}

const clickPlayerSearchButton = () => {
  //ENABLE INPUT FIELD
  if(isInputTextDisabled){setIsInputTextDisabled(false)}
  setClanOrPlayerSearchBoxValue("Enter Player Name")
  setClanSearchButton(false)
  setPlayerSearchButton(true)
}

  const handlePlayerOrClanSearchItemPicking = async (item, playerSearchButton) =>{

    // console.log("handlePlayerOrClanSearchItemPicking : item, playerSearchButton : ", item, playerSearchButton)
    setShowActivity("GETTING NUMBERS FROM WG, IT MAY TAKE A COUPLE OF MINUTES...")
    setIsListOpen(false);

    //WE HAVE TO TAKE RESULTS FROM addPlayerOrClanToLeftListAndPullTheirStats AND THEN ADD THEM TO LEFTLIST
    const res = await addPlayerOrClanToLeftListAndPullTheirStats(item, playerSearchButton === true ? "PLAYER" : "CLAN") //IF playerSearchButton IS TRUE IT'S A PLAYER ACCOUNT, OTHERWISE IT'S A CLAN

    //NEW ITEMS WERE ADDED TO LEFT LIST / ALLPLAYER STATS IN, FILTER / SORT THAT
    setLeftList(prevState => {
      let updatedState = [...prevState];
      if(updatedState.length === 1){
        // NO NEED TO FILTER, ONLY 'Add Clans or Players' PRESENT. IF WE FILTER WITH ONLY 'Add Clans or Players' WE GET EMPTY LIST
      }else{
        updatedState = updatedState.filter(item => item.nickname !== 'Add Clans or Players');
      }      
      return sortListAndRemoveDuplicateObjects(updatedState, 'nickname');
    });

    setShowActivity("")
    setDropdownValues([])
    
  }

  // FUNCTION TO PULL PLAYER DATA
  const addPlayerOrClanToLeftListAndPullTheirStats = async (accountID, type) => {
    //IF playerSearchButton IS TRUE IT'S A PLAYER ACCOUNT, OTHERWISE IT'S A CLAN
    // console.log("addPlayerOrClanToLeftListAndPullTheirStats : accountID, type : ", accountID, type)

    if (type === "PLAYER") {

      //RECORD HIT IN DB FOR STATS PURPOSES
      // console.log("addPlayerOrClanToLeftListAndPullTheirStats : HITTING recordHitDB FOR STATS, TYPE = PLAYER...")
      // const recordHitDB = await api.recordHitDB({ type: "player", accountID: accountID, region: regionDropDownSelectorValue });
      const recordHitDB = await api.recordHitDB({ type: "player", nickname: accountID.nickname, account_id: accountID.account_id, region: regionDropDownSelectorValue });
      // console.log("recordHitDB : TYPE PLAYER : ", recordHitDB)
      
      // console.log("SEARCH BOTH LEFT AND RIGHT LISTS TO SEE IF THIS PLAYER IS ALREADY ON IT")
      let indexLeftList = leftList.findIndex(item => item.nickname === accountID.nickname);
      let indexRightList = rightList.findIndex(item => item.nickname === accountID.nickname);

      
      if (indexLeftList === -1 && indexRightList === -1) {
        //PLAYER NOT FOUND IN EITHER LISTS
        setShowPopup(false);

        const res = await pullPlayerStats(accountID) //RETURNS "PLAYER HAS A HIDDEN PROFILE" OR "ALL GOOD"
        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>addPlayerOrClanToLeftListAndPullTheirStats RES :", res)
        
        if(res === "PLAYER HAS A HIDDEN PROFILE"){
          // customPopup("PLAYER > " + accountID.nickname + " < HAS A HIDDEN PROFILE");
          messageQueue.push("PLAYER > " + accountID.nickname + " < HAS A HIDDEN PROFILE");
          if (messageQueue.length === 1) {
            // If this is the only message in the queue, show it
            customPopup(messageQueue[0]);
          }
          
          // console.log("PLAYER > " + accountID.nickname + " < HAS A HIDDEN PROFILE");
          
        }else{
          //NOT FOUND AND NOT HIDDEN PROFILE, HAVE TO ADD THIS PLAYER TO LEFT LIST
          // console.log("ABOUT TO ADD PLAYERS...")
          setLeftList(prevState => {
            const updatedState = [...prevState];
            updatedState.push({ nickname: accountID.nickname });
            const updatednewListContents = updatedState.filter(item => item.nickname !== 'Add Clans or Players');
            const intermediate = sortListAndRemoveDuplicateObjects(updatednewListContents, 'nickname');
            return intermediate;

          });

        }

      } else {
        // console.log("addPlayerOrClanToLeftListAndPullTheirStats : PLAYER ALREADY EXISTS IN LEFT OR RIGHT LIST, NOT DOING ANYTHING HERE...")
        // customPopup("PLAYER > " + accountID.nickname + " < ALREADY ADDED");
        messageQueue.push("PLAYER > " + accountID.nickname + " < ALREADY ADDED");
        if (messageQueue.length === 1) {
          // If this is the only message in the queue, show it
          customPopup(messageQueue[0]);
        }
      }

    }else if(type === "CLAN") {

      //RECORD HIT IN DB FOR STATS PURPOSES
      // console.log("addPlayerOrClanToLeftListAndPullTheirStats : HITTING recordHitDB FOR STATS, TYPE = CLAN...")
      const recordHitDB = await api.recordHitDB({ type: "clan", nickname: accountID.nickname, account_id: accountID.account_id, region: regionDropDownSelectorValue });
      // console.log("recordHitDB : TYPE CLAN : ", recordHitDB)

      //HERE WE PROCESS CLAN SEARCH PICK
      //GET ALL MEMBERS, RETURN DATA LIKE THIS:
      // { nickname: 'qwe00', account_id: 1054326451 },
      // { nickname: 'qwe080321qwe', account_id: 1053772254 },
      // { nickname: 'qwe10015', account_id: 1014906132 },
      const res = await api.add_player_names_to_available_list({ clanName: accountID, region: regionDropDownSelectorValue });
      // console.log("addPlayerOrClanToLeftListAndPullTheirStats : addPlayerOrClanToLeftListAndPullTheirStats : PLAYER NAMES :", res.data.data)
      
      //LET'S SEE HOW LONG IT TAKES
      const startTime = performance.now();
      
      // const promises = res.data.data.map(async (player) => {
      //     await addPlayerOrClanToLeftListAndPullTheirStats(player, "PLAYER");
      // });

      const promises = res.data.data
        .sort((a, b) => {
          const playerA = a.nickname.toLowerCase();
          const playerB = b.nickname.toLowerCase();

          if (playerA < playerB) {
            return -1; // Return positive value to sort in descending order
          } else if (playerA > playerB) {
            return 1; // Return negative value to sort in descending order
          } else {
            return 0;
          }
        })
        .map(async (player, index, array) => {
          setShowActivity(`PROCESSING PLAYER ${player.nickname}, ${index + 1} OF ${array.length}`);
          await addPlayerOrClanToLeftListAndPullTheirStats(player, "PLAYER");
        });

      await Promise.all(promises);

      const endTime = performance.now();
      const executionTime = ((endTime - startTime) / 1000).toFixed(2)
      console.log(`addPlayerOrClanToLeftListAndPullTheirStats : COMPLETED ${res.data.data.length} PLAYER API CALLS FOR CLAN ${accountID.nickname} in ${executionTime} SECONDS.`)
      
      return accountID

    }else{
      // console.log("addPlayerOrClanToLeftListAndPullTheirStats : INCORRECT TYPE, SHOULD BE PLAYER OR CLAN, IT IS : ", type)
    }

    //EITHER WAY, RETURN accountID
    return accountID
  }

  const pullPlayerStats = async (accountID) => {
    // console.log("pullPlayerStats : accountID : ", accountID)
    //ADD REGION TO THE DATA SENT OVER
    accountID.region = regionDropDownSelectorValue
    //ADD THE FULL LIST OF SHIPS TO THE DATA SENT OVER, WE ALREADY PULLED THIS AT THE START AND THERE IS NO NEED TO PULL IT AGAIN FOR EACH PLAYER
    accountID.fullShipList = fullShipList
    //DO WE HAVE DATA IN THE DB ALREADY? IF NOT OR TOO OLD, PULL IT FROM WG, SAVE INTO DB AND PUSH DATA INTO allPlayerStats
    // console.log("PULLING DATA from DB ACCOUNTID : ", accountID)
    const res = await api.getPlayerShipStats(accountID); // GOES TO MOVIE-CTRL WOWs_GetPlayerShipStats FUNCTION
    setShowActivity(`PROCESSING PLAYER ${accountID.nickname}`);
    // console.log("pullPlayerStats : res : ", res)
    // console.log("pullPlayerStats : res.data.message : ", res.data.message)
    // console.log("pullPlayerStats : res.data.data : ", res.data.data)
  
    if(res.data.message === "PLAYER HAS A HIDDEN PROFILE"){
      //NOT ADDING THIS DICKWAD
      // console.log("pullPlayerStats : ", accountID, "PLAYER HAS A HIDDEN PROFILE")
      return "PLAYER HAS A HIDDEN PROFILE"
  
    }else{
      const entries = Object.entries(res.data.data);
  
      setAllPlayerStats(prevState => {
        const updatedState = {...prevState};
        entries.forEach(([key, value]) => {
          updatedState[key] = value;
        });
        return updatedState;
      });

      return "ALL GOOD"
    }   
  };

  // Function to move items from the left list to the right list
  const moveToRight = (item) => {

    if(item.nickname === "Add Clans or Players"){
      // console.log("NOT DOING JACK SHIT")
    }else{

      // Remove the item from the left list
      let newLeftList = sortListAndRemoveDuplicateObjects(leftList.filter((i) => i !== item),'nickname')
      setLeftList(newLeftList);
      // console.log("leftList : ", newLeftList)

      if(leftList.length === 1){
        setLeftList([{nickname : "Add Clans or Players"}])
      }

      // Add the item to the right list
      let newRightList = ""

      if(item.nickname !== "Add Clans or Players"){
        // const updatednewListContents = rightList.filter(item => item.nickname !== 'None Yet');
        // setRightList(sortListAndRemoveDuplicateObjects([...updatednewListContents.filter((i) => i !== item), item], 'nickname'));
        newRightList = sortListAndRemoveDuplicateObjects([...rightList.filter(item => item.nickname !== 'None Yet').filter((i) => i !== item), item], 'nickname')
        setRightList(newRightList);
        
      }
      // //NOW THE RIGHT LIST CONTAINS PLAYERS, PUT TOGETHER allPlayers
      // console.log("RIGHT LIST : ", newRightList)
    }
  }

  // Function to move items from the right list to the left list
  const moveToLeft = (item) => {

    if(item.nickname === "None Yet"){
      // console.log("NOT DOING JACK SHIT")
    }else{
      //Remove the item from the right list
      let newRightList = sortListAndRemoveDuplicateObjects(rightList.filter((i) => i !== item), 'nickname')
      setRightList(newRightList);
      // console.log("rightList : ", newRightList)
      if(rightList.length === 1){
        setRightList([{nickname: "None Yet"}])
      }
      // Add the item to the left list
      if(item.nickname !== "None Yet"){
        setLeftList(sortListAndRemoveDuplicateObjects([...leftList.filter(item => item.nickname !== 'Add Clans or Players').filter((i) => i !== item), item], 'nickname'));
      }
    }
  }

  const moveToShipRight = item => {
    setRightShipList(sortListObjects([...rightShipList.filter(item => item.Header !== "Add Comp Ships"), item], 'Header'));
   };
  
  // Function to move items from the right list to the left list
  const moveToShipLeft = (item) => {
    if(item.Header === "Add Comp Ships"){
      // console.log("NOT DOING JACK SHIT")
    }else{

      // Remove the item from the right list
      const index = rightShipList.indexOf(item);
      const newRightShipList = [...rightShipList];
      newRightShipList.splice(index, 1);
      setRightShipList(newRightShipList);

      let targetIndex;
      columns.forEach((column, index) => {
        if (column._id === item._id) {
          targetIndex = index;
          return;
        }
      });
      if (targetIndex !== undefined) {
        columns.splice(targetIndex, 1);
        setColumns([...columns]);
      }
      
      // console.log("rightShipList : ", rightShipList)
      if(rightShipList.length === 1){
        setRightShipList([{Header: "Add Comp Ships"}])
      }
    }
  }

  useEffect(() => {
    async function fetchData() {
      setShowActivity("LOADING AVAILABLE SHIPS...")
      const list = await handlePullWGShipList();
      setShowActivity("")

      // console.log("useEffect : handlePullWGShipList : list :", list)
      setLeftShipList(sortListObjects(list, 'Header'));
      setFullShipList(sortListObjects(list, 'Header'));
    }
    
    fetchData();
   
    // console.log("LeftShipList : ", leftShipList)
  }, []);

  useEffect(() => {
    //PULL SHIP EXPECTED VALUES FROM WOWS NUMBERS OR DB
    async function fetchData() {
        // console.log("CALLING useEffect : handlePullWGShipExpectedValues")
        const list = await handlePullWGShipExpectedValues();
        setAllShipExpectedValues(list);
    }
    fetchData();
    // console.log("LeftShipList : ", leftShipList)
  }, []);



  // useEffect(() => {
  //   console.log("useEffect : allPlayerStats ****************************: ", allPlayerStats)
  // }, [allPlayerStats]);

  // useEffect(() => {
  //   console.log("useEffect : leftList ****************************: ", leftList)
  // }, [leftList]);

  useEffect(() => {

    if(!rightShipList.some(ship => ship.Header === 'Add Comp Ships') && !rightList.some(ship => ship.nickname === "None Yet")){

      setShowTable(true)
      // console.log("TABLE = TRUE, columns : ", columns)
      // console.log("rightShipList : ", rightShipList)
      var rightShipListLocal = rightShipList
      // console.log("rightShipListLocal : ", rightShipListLocal)

      //ROWDATA KEEPS A ROW WITH ALL THE PR VALUES FOR EACH SHIP IN RIGHT LIST
      let rowData = []
      rightList.forEach(player => {
          console.log("player ***************: ", player)
          // console.log("allPlayerStats*** ***************: ", allPlayerStats)

          // let thisPlayerStats = allPlayerStats[player.nickname];
//***************** TEST WITH BOTERBERG ZERO SHIPS PREVENT CRASHES ********************************* */
          // console.log(player.nickname, "'S PLAYER STATS >>>>>>>>>>>>>>>>>>>>>>>> : ", allPlayerStats[player.nickname])

          const row = {
            'nickname': player.nickname
          }

          allPlayerStats[player.nickname].forEach(ship => {
            // console.log("allPlayerStats[player.nickname].forEach(ship : ", ship)


            if (rightShipListLocal.some(s => s.ship_id === ship.ship_id)) {
              row['ship_id'] = ship.ship_id;
              row[ship.ship_id] = calculatePR(ship);
            }
          })
          // console.log("*************************************row : ", row)
          rowData = [...rowData, row]
      })

      // console.log("*************************************rowData : ", rowData)
      // console.log("TABLE = TRUE, rowData : ", rowData)
      setallPlayers(rowData)

      //HERE WE COME UP WITH RECOMMENDATIONS TO BE USED IN THE TABLE
      // console.log("RECOMMENDATION CALCULATOR : >>>>>>>>>>>>>>>>>>>>>>>>> rowData : ", rowData)
      // console.log("RECOMMENDATION CALCULATOR : >>>>>>>>>>>>>>>>>>>>>>>>> rightShipListLocal : ", rightShipListLocal)
      // console.log("RECOMMENDATION CALCULATOR : >>>>>>>>>>>>>>>>>>>>>>>>> rowData.length : ", rowData.length)
      // console.log("RECOMMENDATION CALCULATOR : >>>>>>>>>>>>>>>>>>>>>>>>> columns : ", columns)
      // console.log("RECOMMENDATION CALCULATOR : >>>>>>>>>>>>>>>>>>>>>>>>> columns.length : ", columns.length)
     
      const recommendationData = [];

      rightShipListLocal.forEach((shipOnRightList) => {
        const thisShip = {ship_name: shipOnRightList.Header, ship_id: shipOnRightList.ship_id, data: []};
        
        rowData.forEach((playersData) => {
          if (playersData.hasOwnProperty(shipOnRightList.ship_id)
            && (playersData[shipOnRightList.ship_id] !== 'NO SHIP ID' 
            && playersData[shipOnRightList.ship_id] !== 'NO DATA FOR THIS SHIP' 
            && playersData[shipOnRightList.ship_id] !== 'NO GAMES'
            && !(typeof playersData[shipOnRightList.ship_id] === "string" && playersData[shipOnRightList.ship_id].toLowerCase().startsWith("only") && playersData[shipOnRightList.ship_id].toLowerCase().endsWith("games"))
            && !(typeof playersData[shipOnRightList.ship_id] === "string" && playersData[shipOnRightList.ship_id].toLowerCase().startsWith("pick game modes"))
            
            )) {

            thisShip.data.push({
              Player: playersData['nickname'],
              Ship_Name: shipOnRightList.Header,
              Ship_Id: shipOnRightList.ship_id,
              PR: playersData[shipOnRightList.ship_id]
            });
      
            // console.log("playersData[nickname]", playersData['nickname'], "shipOnRightList.header: ", shipOnRightList.Header, " Value: ", playersData[shipOnRightList.ship_id]);
          }
        });
      
        recommendationData.push(thisShip);
      });
      
      recommendationData.sort((a, b) => {
        return a.data.length - b.data.length;
      });
      
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>recommendationData : ", recommendationData);
      
      //THIS DECIDES WHO PLAYS WHAT
      let assignments = assignmentEngine(recommendationData)

      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>assignments : ", assignments);
      // console.log("****************************************START COLUMN CREATION LOOP ******************************************")
      
      let allColumns = []
      let assignedPlayers = [];
      
      rightShipListLocal.forEach((shipOnRightList) => {
        // console.log("PROCESSING rightShipListLocal.forEach((shipOnRightList) : ", shipOnRightList)
      
        const filteredAssignments = assignments.filter(a => a.Ship_Id === shipOnRightList.ship_id && !assignedPlayers.includes(a.Player));
      
        const best_player = filteredAssignments.length > 0 ? filteredAssignments[0].Player : null;
        // console.log("best_player :", best_player)
        if (best_player) {
          assignedPlayers.push(best_player);
          
        }
        // console.log("assignedPlayers :", assignedPlayers)
        
        let newColumn = {
          Header: shipOnRightList.Header,
          accessor: shipOnRightList.ship_id.toString(),
          best_player: best_player,
          ship_id: shipOnRightList.ship_id,
          // headerStyle: { fontWeight: "bold" },
          Cell: ({ value, row }) => cellFormatter(shipOnRightList.Header, value, `${shipOnRightList.Header} - ${best_player}`, row.nickname),
        }
      
        allColumns = sortListObjects([...allColumns, newColumn], "Header")
        
      });
      

      //ADD "PLAYER" TO RENDER TABLE WITH LEFT MOST COLUMN BEING PLAYER NAMES
      const playerName = {
        Header: "Player",
        accessor: 'nickname',
        // style: { textAlign: "center", fontWeight: "bold" },
        style: {textAlign: "center"},
        // headerStyle: { fontWeight: "bold" },
      };
    
      allColumns=[playerName, ...allColumns]
      // console.log("FINAL VERSION OF allColumns : ", allColumns)
      setColumns(allColumns)

    }else{
      setShowTable(false)
    }
  }, [rightList, rightShipList, minGamesToQualifyForPRCalculation, pvpModeChanged, rankModeChanged]);


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////BELOW CODE RUNS ALL POSSIBLE COMBINATIONS, ABOUT 25 MILLION FOR 7X7 RECOMMENDATION DATA///////////////////////////////////////////////////////////////////

// let allSolutions = [];
// let allDamnCombos = [];

// recommendationData.forEach(combo => {
//   combo.data.forEach(item => {
//     allDamnCombos.push(item);
//   });
// });

// console.log("allDamnCombos", allDamnCombos);
// console.log("allDamnCombos", allDamnCombos.length);

// // LOOP FOR EACH COMBO
// // LOOP GETS FULL allDamnCombos EVERY TIME, RECORDS THE CURRENT ITERATION, FILTERS OUT WHAT WAS SEEN BEFORE, CALLS ITSELF AGAIN WITH FULL ALLCOMBOS AND ACCUMULATED FILTERS.
// // WHEN IT HITS ZERO OPTIONS, SENDS THE RECORD TO POSSIBLE OPTIONS

// let masterCounter = 0

// const tryThis = async (allCombos, assignedPlayers, assignedShips, possibleCombinationProgress, iterCounter) => {

//   for (const singleCombo of allCombos) {
    
//     masterCounter++;

//     if(masterCounter % 1000 === 0){

//       console.log("CHECKPOINT HERE")
//       console.log("allSolutions:", allSolutions)
//     }

//     var possibleCombinationProgressCopy = possibleCombinationProgress.slice();
//     var assignedPlayersCopy = assignedPlayers.slice();
//     var assignedShipsCopy = assignedShips.slice();
    
//     var iterCounterCopy = iterCounter;
//     iterCounterCopy++;
//     // console.log("******PROCESSING COMBO, itercounter, masterCounter :", singleCombo, iterCounterCopy, masterCounter)

//     //ADD PROGRESS
//     possibleCombinationProgressCopy = [...possibleCombinationProgressCopy, singleCombo]

//     if (!assignedPlayersCopy.includes(singleCombo.Player)) {
//       assignedPlayersCopy.push(singleCombo.Player);
//     }
    
//     // IF CHECK NOT NEEDED IF CHECKED ALREADY
//     if (!assignedShipsCopy.includes(singleCombo.Ship_Name)) {
//       assignedShipsCopy.push(singleCombo.Ship_Name);
//     }

//     const allCombosFiltered = allCombos.filter(combo => singleCombo.Player !== combo.Player && singleCombo.Ship_Name !== combo.Ship_Name);
//     // console.log("******allCombosFiltered.length:", allCombosFiltered.length);

//     if (allCombosFiltered.length === 0) {
//       //ALL FILTERED OUT, NO MORE OPTIONS. PUSH WHATEVER WE HAVE AND RESET.
//       // console.log("******THAT'S IT, ZEROED OUT, PUSHING possibleCombinationProgressCopy TO allSolutions");
//       //CHECK IF THIS SOLUTION HAS THE REQUIRED NUMBER OF PLAYER TO SHIP MAPPINGS
//       if(possibleCombinationProgressCopy.length === recommendationData.length){
//         allSolutions.push(possibleCombinationProgressCopy)

//       }
      
//     }else{

//       await tryThis(allCombosFiltered, assignedPlayersCopy, assignedShipsCopy, possibleCombinationProgressCopy, iterCounterCopy);
//     };
//   }

// }

// console.log("*********************************************************************************************************************************************************************************");
// console.log(" NEW STARTS HERE");
// console.log("*********************************************************************************************************************************************************************************");

// (async () => {
//   try {
//     await tryThis(allDamnCombos, [], [], [], 0);
//     // // console.log("allSolutions:", allSolutions)
//  console.log("allSolutions.LENGTH:", allSolutions.length);
//   } catch (error) {
//     console.error(error);
//   }
// })();


///////////////////////////////////////ABOVE CODE RUNS ALL POSSIBLE COMBINATIONS, ABUT 25 MILLION FOR 7X7 RECOMMENDATION DATA///////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//******************************************************************************************************************* */



  const assignmentEngine = (recommendationData) => {

    //THIS USES MUNKRES / HUNGARIAN ALGORITHM FOR ASSIGNMENT OPERATIONS

    //CASE SHIPS = PLAYERS - WORKS ( 7 SHIPS 7 PLAYERS EACH)
    //CASE EACH SHIP HAS LESS THAN SHIP PLAYERS = WORKS (7 SHIPS 5 PLAYERS EACH)
    //CASE EACH SHIP HAS MORE THAN SHIP PLAYERS = WORKS (7 SHIPS 9 PLAYERS)
    //CASE 3 SHIPS WITH THE SAME TWO PLAYERS = WORKS (munkresRecommendation COMES BACK WITH ONE RECOMMENDATION THAT IS UNDEFINED SO WE JUST DON'T PUSH THAT INTO munkresAssignments)

            
    let sortedShipNames = [];
    let sortedPlayerNames = [];

    recommendationData.forEach((ship) => {
      sortedShipNames.push(ship.ship_name);
      ship.data.forEach((player) => {
        if (!sortedPlayerNames.includes(player.Player)) {
          sortedPlayerNames.push(player.Player);
        } else {
          //ALREADY ADDED
        }
      });  
    });

    // SORT BOTH ARRAYS - WE NEED TO SORT BOTH AS WE CAN ONLY FEED PR DATA TO MUNKRES FUNCTION, NOT PLAYER OR SHIP NAMES.
    // WHEN THE DATA COMES BACK FROM MUNKRES IT NEEDS TO BE PROPERLY MAPPED BACK TO PLAYERS AND SHIPS
    sortedShipNames.sort();
    sortedPlayerNames.sort();

    // console.log("sortedShipNames:", sortedShipNames);
    // console.log("sortedPlayerNames:", sortedPlayerNames);
    // console.log("sortedShipNames.length:", sortedShipNames.length);
    // console.log("sortedPlayerNames.length:", sortedPlayerNames.length);

    let longerArrayLength;
    let dataForMunkres = [];

    if (sortedShipNames.length > sortedPlayerNames.length) {
      longerArrayLength = sortedShipNames.length;
    } else {
      longerArrayLength = sortedPlayerNames.length;
    }

    let prValue;

    for (let i = 0; i < longerArrayLength; i++) {
      // OUTER LOOP
      // console.log("sortedShipNames[i]:", sortedShipNames[i]);
      let perPlayerPRData = [];

      for (let j = 0; j < longerArrayLength; j++) {
        // console.log("   sortedPlayerNames[j]:", sortedPlayerNames[j]);
        
        // FIND PR VALUE FOR THE CURRENT SHIP AND PLAYER
        prValue = recommendationData.find(ship => ship.ship_name === sortedShipNames[i])?.data.find(player => player.Player === sortedPlayerNames[j])?.PR;
        // console.log("   PRValue:", prValue);
        if(prValue === undefined){
          // console.log("ASSIGNING PRVALUE MINUS 1000000")
          prValue = -1000000
        }
        // PUSH THE PR VALUE TO THE DATA ARRAY
        //THIS MINUS IN FRONT OF PRVALUE IS WHAT MAKES IT CALCULATE THE HIGHEST PR, NOT THE LOWEST WHICH IS DEFAULT
        perPlayerPRData = [...perPlayerPRData, -prValue]
        
        
      }
      // console.log("perPlayerPRData:", perPlayerPRData);
      dataForMunkres.push(perPlayerPRData)
    
    }

    // console.log("dataForMunkres:", dataForMunkres);
    // console.log("dataForMunkres.length:", dataForMunkres.length);


    let munkresRecommendation = munkres(dataForMunkres)
    // console.log("munkresRecommendation:", munkresRecommendation)

    //NOW HAVE TO PUT IT ALL BACK TOGETHER
    let munkresAssignments = []
    munkresRecommendation.forEach(assignment => {
      // console.log("assignment:", assignment)
      let temp = {Player: sortedPlayerNames[assignment[1]], Ship_Name: sortedShipNames[assignment[0]], Ship_Id: recommendationData.find(ship => ship.ship_name === sortedShipNames[assignment[0]])?.data.find(player => player.Player === sortedPlayerNames[assignment[1]])?.Ship_Id, PR: (recommendationData.find(ship => ship.ship_name === sortedShipNames[assignment[0]])?.data.find(player => player.Player === sortedPlayerNames[assignment[1]])?.PR)}
      // console.log("temp: ", temp)
      if(temp.PR !== undefined && temp.Ship_Name !== undefined && temp.Ship_Id !== undefined){
        //Ship_Name: undefined, Ship_Id: undefined, PR: undefined, ALL EXCEPT PLAYER ARE UNDEFINED, THIS HAPPENS WHEN THERE ARE MORE PLAYERS THAN SHIPS.
        //IN THIS CASE SIMPLY DON'T ASSIGN THAT PLAYER TO munkresAssignments
        munkresAssignments = [...munkresAssignments, temp]

      }
      
    })

    return munkresAssignments
  }

  const calculatePR = (shipData) => {

    // console.log("calculatePR : ENTER")

    // allShipExpectedValues VALUES PULLED FROM WOWS NUMBERS
    // HATE THE DEPENDENCY ON 3RD PARTY BUT NO IDEA WHERE TO GET SERVER STATS
    // https://wows-numbers.com/personal/rating
    // https://api.wows-numbers.com/personal/rating/expected/json/

    //CALCULATE FOR EACH MODE SEPARATELY
    const damagePerGamePvpSolo = shipData.pvp_solo_damage_dealt / shipData.pvp_solo_battles
    const winsPerGamePvpSolo =  (shipData.pvp_solo_wins         / shipData.pvp_solo_battles) * 100 //PERCENTAGE
    const killsPerGamePvpSolo = shipData.pvp_solo_frags         / shipData.pvp_solo_battles

    const damagePerGamePvpDiv2 = shipData.pvp_div2_damage_dealt / shipData.pvp_div2_battles
    const winsPerGamePvpDiv2 =  (shipData.pvp_div2_wins         / shipData.pvp_div2_battles) * 100 //PERCENTAGE
    const killsPerGamePvpDiv2 = shipData.pvp_div2_frags         / shipData.pvp_div2_battles

    const damagePerGamePvpDiv3 = shipData.pvp_div3_damage_dealt / shipData.pvp_div3_battles
    const winsPerGamePvpDiv3 =  (shipData.pvp_div3_wins         / shipData.pvp_div3_battles) * 100 //PERCENTAGE
    const killsPerGamePvpDiv3 = shipData.pvp_div3_frags         / shipData.pvp_div3_battles



    const damagePerGameRankSolo = shipData.rank_solo_damage_dealt / shipData.rank_solo_battles
    const winsPerGameRankSolo =  (shipData.rank_solo_wins         / shipData.rank_solo_battles) * 100 //PERCENTAGE
    const killsPerGameRankSolo = shipData.rank_solo_frags         / shipData.rank_solo_battles

    const damagePerGameRankDiv2 = shipData.rank_div2_damage_dealt / shipData.rank_div2_battles
    const winsPerGameRankDiv2 =  (shipData.rank_div2_wins         / shipData.rank_div2_battles) * 100 //PERCENTAGE
    const killsPerGameRankDiv2 = shipData.rank_div2_frags         / shipData.rank_div2_battles

    const damagePerGameRankDiv3 = shipData.rank_div3_damage_dealt / shipData.rank_div3_battles
    const winsPerGameRankDiv3 =  (shipData.rank_div3_wins         / shipData.rank_div3_battles) * 100 //PERCENTAGE
    const killsPerGameRankDiv3 = shipData.rank_div3_frags         / shipData.rank_div3_battles

    // if(shipData.ship_id === 4179604944){
    //   //NAKHIMOV
    //   console.log("**********************shipData : ", shipData)
    //   console.log(">>>>>>>>>>>>>>>>>>>>>>shipData.pvp_solo_battles : ", shipData.pvp_solo_battles)
    //   console.log(">>>>>>>>>>>>>>>>>>>>>>shipData.pvp_div2_battles : ", shipData.pvp_div2_battles)
    //   console.log(">>>>>>>>>>>>>>>>>>>>>>shipData.pvp_div3_battles : ", shipData.pvp_div3_battles)
    //   if(shipData.pvp_solo_battles === 0){
    //     console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>damagePerGamePvpSolo : ", damagePerGamePvpSolo)
    //   }
    // }

    //NOW ADD IT UP DEPENDING ON WHAT MODE IS SELECTED
    // let damagePerGameSelected = 0
    // let winsPerGameSelected = 0
    // let killsPerGameSelected = 0
    

    //THIS KEEPS TOTAL PR FOR A WEIGHTED AVERAGE
    let prTotal = 0
    let gamesInSelectedModesTotal = 0
    let pvpModeSoloPR = 0
    let pvpModeDiv2PR = 0
    let pvpModeDiv3PR = 0

    let rankModeSoloPR = 0
    let rankModeDiv2PR = 0
    let rankModeDiv3PR = 0

    //COMBINE SELECTED PVP MODES
    if(pvpModeAllRandom || pvpModeSolo){
      if (shipData.pvp_solo_battles !== 0) {
        // console.log("pvpModeAllRandom || pvpModeSolo")
        pvpModeSoloPR = perSelectedModePRCalculator(damagePerGamePvpSolo, winsPerGamePvpSolo, killsPerGamePvpSolo, shipData)
        if (typeof pvpModeSoloPR === 'number') {
          prTotal += pvpModeSoloPR * shipData.pvp_solo_battles
        }
        gamesInSelectedModesTotal += shipData.pvp_solo_battles
      }
    }

    if(pvpModeAllRandom || pvpModeDiv2){
      if (shipData.pvp_div2_battles !== 0) {
        // console.log("pvpModeAllRandom || pvpModeDiv2")
        pvpModeDiv2PR = perSelectedModePRCalculator(damagePerGamePvpDiv2, winsPerGamePvpDiv2, killsPerGamePvpDiv2, shipData)
        if (typeof pvpModeDiv2PR === 'number') {
          prTotal += pvpModeDiv2PR * shipData.pvp_div2_battles
        }
        gamesInSelectedModesTotal += shipData.pvp_div2_battles
      }
    }

    if(pvpModeAllRandom || pvpModeDiv3){
      if (shipData.pvp_div3_battles !== 0) {
        // console.log("pvpModeAllRandom || pvpModeDiv3")
        pvpModeDiv3PR = perSelectedModePRCalculator(damagePerGamePvpDiv3, winsPerGamePvpDiv3, killsPerGamePvpDiv3, shipData)
        if (typeof pvpModeDiv3PR === 'number') {
          prTotal += pvpModeDiv3PR * shipData.pvp_div3_battles
        }
        gamesInSelectedModesTotal += shipData.pvp_div3_battles
      }
    }


    //COMBINE SELECTED RANK MODES
    if(rankModeAllRank || rankModeSolo){
      if (shipData.rank_solo_battles !== 0) {
        // console.log("rankModeAllRank || rankModeSolo")
        rankModeSoloPR = perSelectedModePRCalculator(damagePerGameRankSolo, winsPerGameRankSolo, killsPerGameRankSolo, shipData)
        if (typeof rankModeSoloPR === 'number') {
          prTotal += rankModeSoloPR * shipData.rank_solo_battles
        }
        gamesInSelectedModesTotal += shipData.rank_solo_battles
      }
    }

    if(rankModeAllRank || rankModeDiv2){
      if (shipData.rank_div2_battles !== 0) {
        // console.log("rankModeAllRank || rankModeDiv2")
        rankModeDiv2PR = perSelectedModePRCalculator(damagePerGameRankDiv2, winsPerGameRankDiv2, killsPerGameRankDiv2, shipData)
        if (typeof rankModeDiv2PR === 'number') {
          prTotal += rankModeDiv2PR * shipData.rank_div2_battles
        }
        gamesInSelectedModesTotal += shipData.rank_div2_battles
      }
    }

    if(rankModeAllRank || rankModeDiv3){
      if (shipData.rank_div3_battles !== 0) {
        // console.log("rankModeAllRank || rankModeDiv3")
        rankModeDiv3PR = perSelectedModePRCalculator(damagePerGameRankDiv3, winsPerGameRankDiv3, killsPerGameRankDiv3, shipData)
        if (typeof rankModeDiv3PR === 'number') {
          prTotal += rankModeDiv3PR * shipData.rank_div3_battles
        }
        gamesInSelectedModesTotal += shipData.rank_div3_battles
      }
    }


    // console.log("prTotal, gamesInSelectedModesTotal : ", prTotal, gamesInSelectedModesTotal)

    // console.log("pvpModeAllRandom, pvpModeSolo, pvpModeDiv2, pvpModeDiv3, rankModeAllRank, rankModeSolo, rankModeDiv2, rankModeDiv3 : ", pvpModeAllRandom, pvpModeSolo, pvpModeDiv2, pvpModeDiv3, rankModeAllRank, rankModeSolo, rankModeDiv2, rankModeDiv3)


    if(!pvpModeAllRandom && !pvpModeSolo && !pvpModeDiv2 && !pvpModeDiv3 && !rankModeAllRank && !rankModeSolo && !rankModeDiv2 && !rankModeDiv3){
      //NO GAME MODES ARE SELECTED
      console.log("calculatePR : NO GAME MODES SELECTED")
      prTotal = "PICK GAME MODES"
      return(prTotal)

    }else if(gamesInSelectedModesTotal < parseInt(minGamesToQualifyForPRCalculation.replace(/\D/g, ''))){
      //PR NUMBERS AS CALCULATED
      prTotal = "ONLY " + gamesInSelectedModesTotal + " GAMES"
      return(prTotal)

    }else{

      //WEIGHTED AVERAGE FOR ALL MODES
      let weightedAveragePR = 0
      
      weightedAveragePR = gamesInSelectedModesTotal === 0 ? 0 : Math.round(prTotal / gamesInSelectedModesTotal)
      console.log("calculatePR : weightedAveragePR : ", weightedAveragePR)
      return weightedAveragePR

    }

  }


  const perSelectedModePRCalculator = (damagePerGameSelected, winsPerGameSelected, killsPerGameSelected, shipData) => {

    // console.log("perSelectedModePRCalculator : damagePerGameSelected, winsPerGameSelected, killsPerGameSelected :", damagePerGameSelected, winsPerGameSelected, killsPerGameSelected)
   
    let expectedDmg = allShipExpectedValues.data[shipData.ship_id] ? allShipExpectedValues.data[shipData.ship_id].average_damage_dealt : "NO DATA FOR THIS SHIP";
    let expectedWins  = allShipExpectedValues.data[shipData.ship_id] ? allShipExpectedValues.data[shipData.ship_id].win_rate : "NO DATA FOR THIS SHIP";
    let expectedFrags  = allShipExpectedValues.data[shipData.ship_id] ? allShipExpectedValues.data[shipData.ship_id].average_frags : "NO DATA FOR THIS SHIP";

    //IF expectedDmg COMES AS "NO DATA FOR THIS SHIP" IT WILL MAKE CALCS NaN
    let rDmg = damagePerGameSelected/expectedDmg
    let rWins = winsPerGameSelected/expectedWins
    let rFrags = killsPerGameSelected/expectedFrags

    let nDmg = Math.max(0, (rDmg - 0.4) / (1 - 0.4))
    let nFrags = Math.max(0, (rFrags - 0.1) / (1 - 0.1))
    let nWins = Math.max(0, (rWins - 0.7) / (1 - 0.7))

    let pr =  Math.round(700*nDmg + 300*nFrags + 150*nWins)

    // console.log("perSelectedModePRCalculator PR : ", pr)

    return pr

  }


  const cellFormatter = (header, value, best_player_combo, nickname) => {
    
    //THIS EXECUTES ONCE FOR EVERY CELL, SO 7 PLAYERS X 7 SHIPS = 49 EXECUTIONS
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  header, value, best_player_combo, nickname: ", header, value, best_player_combo, nickname);
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  SPACER");
  
    let backgroundColor, color;
    if  (
         value !== undefined 
      && value !== null 
      && value !== "NO SHIP" 
      && value !== "NO DATA FOR THIS SHIP" 
      && value !== "NO GAMES"
      && !(typeof value === "string" && value.toLowerCase().startsWith("only") && value.toLowerCase().endsWith("games"))
      && !(typeof value === "string" && value.toLowerCase().startsWith("pick game modes"))
      ) {


      // backgroundColor = "white";
      if (value >= 0 && value <= 750) {
        color = "#FE0E00";
      } else if (value > 750 && value <= 1100) {
        color = "#FE7903";
      } else if (value > 1100 && value <= 1350) {
        color = "#FFC71F";
      } else if (value > 1350 && value <= 1550) {
        color = "#44B300";
      } else if (value > 1550 && value <= 1750) {
        color = "#318000";
      } else if (value > 1750 && value <= 2100) {
        color = "#02C9B3";
      } else if (value > 2100 && value <= 2450) {
        color = "#D042F3";
      } else if (value > 2450 && value <= 9999) {
        color = "#A00DC5";
      } else {
        color = "black";
      }
    } else {
      // backgroundColor = "white";
      // color = "#9c9c9c";
      color = "#b8b8b8";
      
    }

    //HERE IS THE MAGIC PAINTER
    if(header + " - " + nickname === best_player_combo){

      backgroundColor = "lightgreen";
    }
    
    return (
      <div style={{
        backgroundColor,
        color,
        // 'text-shadow': '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black, 1px 1px 1px #D3D3D3',
        // fontWeight: "bold",
        textAlign: "center",
        // padding: "10px"
      }}>
        {value !== undefined && value !== null ? value : "NO SHIP"}
      </div>
    );
  };

  const MyTable = (props) => {
    return (
      <ReactTable
        data={props.data}
        columns={props.columns}
        defaultPageSize={props.data.length}
        PaginationComponent={({ page, pages, pageSize, pageSizeOptions, ...props }) => null}
        className="-striped -highlight"
        style={{ fontSize: "18px" }}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              background: rowInfo ? rowInfo.index % 2 === 0 ? "#EEEEEE" : "white" : "white",
              border: "solid 1px black",
              // fontSize: "20px",
              // 'text-shadow': "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000"

            },
          };
        }}
      />
    );
  };

  
  return (
    // <ThemeProvider theme={darkTheme}>
      <div>
        <Wrapper>
          <DualListContainer>   
              
            <Button active={clanSearchButton} onClick={() => clickClanSearchButton()}>Clan Search</Button>
            <Button active={playerSearchButton} onClick={() => clickPlayerSearchButton()}>Player Search</Button>
        
            <DropdownContainer>
              <InputText 
                type="text" 
                value={clanOrPlayerSearchBoxValue}
                disabled={isInputTextDisabled}
                onChange={handleChangeSearchClanOrPlayerName} 
                onClick={handleClickSearchClanOrPlayerName}
                onBlur={handleOnBlurSearchClanOrPlayerName}
              
              />
              {isListOpen && (
                <ListForClanAndPlayerSearch>
                  {dropdownValues.map((item, index) => (
                    <ListItem key={item.account_id} highlighted={index % 2 === 0}
                      onClick={() => {
                            handlePlayerOrClanSearchItemPicking(item, playerSearchButton)
                          }}
                    >
                      {item.nickname}
                    </ListItem>

                  ))}
                </ListForClanAndPlayerSearch>
              )}

            </DropdownContainer>

            <InputText 
              type="text" 
              value={minGamesToQualifyForPRCalculation}
              onChange={handleChangeMinGamesToQualifyForCalculation} 
              onClick={handleClickMinGamesToQualifyForCalculation}
              onBlur={handleOnBlurMinGamesToQualifyForCalculation}
            />
      
            <Select value={regionDropDownSelectorValue} onChange={handleChangeRegionDropDownSelector}>

              {regionDropDownSelectorOptions.map((region, index) => (
                <Option key={region} value={region} isEven={index % 2 === 0}>
                {"Region: " + region}
                </Option>
              ))}
            </Select>

            
            <ExtraLeftMarginButton active={pvpModeAllRandom} onClick={() => clickPvpModeAllRandom()}>All Random</ExtraLeftMarginButton>
            <Button active={pvpModeSolo} onClick={() => clickPvpModeSolo()}>Solo</Button>
            <Button active={pvpModeDiv2} onClick={() => clickPvpModeDiv2()}>Div 2</Button>
            <Button active={pvpModeDiv3} onClick={() => clickPvpModeDiv3()}>Div 3</Button>

            <ExtraLeftMarginButton active={rankModeAllRank} onClick={() => clickRankModeAllRank()}>All Ranked</ExtraLeftMarginButton>
            <Button active={rankModeSolo} onClick={() => clickRankModeSolo()}>Solo</Button>
            <Button active={rankModeDiv2} onClick={() => clickRankModeDiv2()}>Div 2</Button>
            <ExtraRightMarginButton active={rankModeDiv3} onClick={() => clickRankModeDiv3()}>Div 3</ExtraRightMarginButton>

            <CancelButton onClick={() => clearAllSelections()}>Clear all selections</CancelButton>
      
          </DualListContainer>

          {/*
            <Button onClick={() => handlePullClanData()}>Add Clan Members</Button>
                  */}   
          <DualListContainer>

            <div>
              <Label>Available Players</Label>
              <List>
                {leftList.map((item, index) => (
                  <ListItem key={`${item.account_id}-${index}`} onClick={() => moveToRight(item)} highlighted={index % 2 === 0}>
                    {item.nickname}
                  </ListItem>
                ))}
              </List>
            </div>

            <div>
              <Label>Selected Players</Label>
              <List>
              {rightList.map((item, index) => (
                  <ListItem key={`${item.account_id}-${index}`} onClick={() => moveToLeft(item)} highlighted={index % 2 === 0}>
                    {item.nickname}
                  </ListItem>
              ))}
              </List>
            </div>

            <div>
              <SearchShipsInputBox
                type="text"
                value={shipListSearchTerm}
                onChange={handleChange_ShipList}
                onClick={handleRestoreFullShipList}
              />

              <List>
                {leftShipList.map((item, index) => (
                  <ListItem key={`${item._id}-${index}`} onClick={() => moveToShipRight(item)} highlighted={index % 2 === 0}>
                    {item.Header}
                  </ListItem>
                ))}
              </List>

            </div>

            <div>
              <Label>Selected Ships</Label>
              <List>
              {rightShipList.map((item, index) => (
                  <ListItem key={`${item._id}-${index}`} onClick={() => moveToShipLeft(item)} highlighted={index % 2 === 0}>
                  {item.Header}
                  </ListItem>
              ))}
              </List>
            </div>

          </DualListContainer>
                

            {/* <div style ={{display: 'flex'}}>
            <span style={{ marginRight: '10px' }}>Nation:</span>  
              <Checkbox id="checkbox-nation-ALL" value="check me" label="ALL" />
              <Checkbox id="checkbox-nation-UK" value="check me" label="UK" />
              <Checkbox id="checkbox-nation-USA" value="check me" label="USA" />
              <Checkbox id="checkbox-nation-USSR" value="check me" label="USSR" />
              <Checkbox id="checkbox-nation-Italy" value="check me" label="Italy" />
              <Checkbox id="checkbox-nation-France" value="check me" label="France" />
              <Checkbox id="checkbox-nation-Europe" value="check me" label="Europe" />
              <Checkbox id="checkbox-nation-Japan" value="check me" label="Japan" />
              <Checkbox id="checkbox-nation-spain" value="check me" label="Spain" />
              <Checkbox id="checkbox-nation-Pan-Asia" value="check me" label="Pan Asia" />
              <Checkbox id="checkbox-nation-Pan-America" value="check me" label="Pan America" />
              <Checkbox id="checkbox-nation-Commonwealth" value="check me" label="Commonwealth" />
              <Checkbox id="checkbox-nation-Germany" value="check me" label="Germany" />
              <Checkbox id="checkbox-nation-netherlands" value="check me" label="Netherlands" />
            </div>
          

            <div style ={{display: 'flex'}}>
            <span style={{ marginRight: '10px' }}>Tier:</span>  
              <Checkbox id="checkbox-tier-ALL" value="check me" label="ALL" />
              <Checkbox id="checkbox-tier-1" value="check me" label="1" />
              <Checkbox id="checkbox-tier-2" value="check me" label="2" />
              <Checkbox id="checkbox-tier-3" value="check me" label="3" />
              <Checkbox id="checkbox-tier-4" value="check me" label="4" />
              <Checkbox id="checkbox-tier-5" value="check me" label="5" />
              <Checkbox id="checkbox-tier-6" value="check me" label="6" />
              <Checkbox id="checkbox-tier-7" value="check me" label="7" />
              <Checkbox id="checkbox-tier-8" value="check me" label="8" />
              <Checkbox id="checkbox-tier-9" value="check me" label="9" />
              <Checkbox id="checkbox-tier-10" value="check me" label="10" />
              <Checkbox id="checkbox-tier-11" value="check me" label="11" />

            </div>

            <div style ={{display: 'flex'}}>
            <span style={{ marginRight: '10px' }}>Type:</span>  
              <Checkbox id="checkbox-type-ALL" value="check me" label="ALL" />
              <Checkbox id="checkbox-type-CV" value="check me" label="CV" />
              <Checkbox id="checkbox-type-BB" value="check me" label="BB" />
              <Checkbox id="checkbox-type-CA" value="check me" label="CA" />
              <Checkbox id="checkbox-type-DD" value="check me" label="DD" />
              <Checkbox id="checkbox-type-SUB" value="check me" label="SUB" />

            </div> */}
              
          {showActivity && <Label>{showActivity}</Label>}
          <div>
          </div>
          {/*showTable && <h3>Who's playing what :</h3>*/}

          {showTable && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  backgroundColor: "lightgreen",
                  fontSize: "20px",
                  padding: "0px",
                }}
              >
                Who's playing what :
              </span>
            </div>
          )}
          


          {showTable && (
              
            <MyTable
              data={allPlayers}
              columns={columns}
              minRows={0}
            />
          )}
          
        </Wrapper>
      </div>
    // </ThemeProvider>
  );
};

export default WOWs_CB_Team_Composer;
