import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { NavBar } from '../components'
import { WOWs_Get_All_WG_Ships, WOWs_List_All_WG_Ships, WOWs_Grant_Access_Return, WOWs_CB_Team_Composer } from '../pages'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
    return (
        <Router>
            <NavBar />
            <Switch>
                <Route path='/WOWs/Grant_Access' exact component={() => { 
                    window.location.href = 'https://api.worldoftanks.com/wot/auth/login/?application_id=8ded942baaf1db607a1aca4cc67a47ee&redirect_uri=http%3A%2F%2Flocalhost%3A3001%2FWOWs%2FGrant_Access_Return%2F'; 

                    return null;
                }}/>
                <Route path="/WOWs/Get_All_WG_Ships" exact component={WOWs_Get_All_WG_Ships} />
                <Route path="/WOWs/List_All_WG_Ships" exact component={WOWs_List_All_WG_Ships} />
                <Route path="/WOWs/Grant_Access_Return" exact component={WOWs_Grant_Access_Return} />
                <Route path="/" exact component={WOWs_CB_Team_Composer} />
                {/* <Route path="/WOWs/CB_Team_Composer" exact component={WOWs_CB_Team_Composer} /> */}
            </Switch>
        </Router>
    )
}

export default App
