import React from 'react'
import { render } from 'react-dom';

import App from './app'
// const ReactDOM = require('react-dom');

render(<App />, document.getElementById('root'));

// const React = require('react');
// const ReactDOM = require('react-dom');
// const App = require('./app').default; // Use `.default` to access the default export

// ReactDOM.render(<App />, document.getElementById('root'));

// class SingleItem extends React.Component {
//     render() {
//       let data = this.props.data;
  
//       return (
//           <li onClick={this.props.onClick} >
//               <div> 
//                 {data.name}
//               </div>
//           </li>
//       );
//     }
//   }
  
//   class ItemList extends React.Component {
//      render() {
//        let itemArr = this.props.allItems;
//        let myItems = this.props.items;
//        let handleEvent = this.props.handleEvent;
  
//        let listItems = itemArr.map((itemObj) => {
//           if (!myItems.includes(itemObj.id)) return null;
  
//           return <SingleItem 
//             key={itemObj.id}
//             data={itemObj}
//             onClick={() => handleEvent(itemObj.id)}
//           />;
//        });
  
//        return (
//           <ul>
//               {listItems}
//           </ul>
//        );
//      }
//   }
  
//   class App extends React.Component {
//   constructor(props) {
//       super(props);
  
//       this.state = {
//           boxOne: props.items.map(item => item.id), // init the boxes with itemIds
//           boxTwo: []
//       };
  
//       this.handleEvent = this.handleEvent.bind(this);
//   }
  
//   handleEvent(itemId) {
//       const isInBoxOne = this.state.boxOne.includes(itemId);
  
//       // Heres the magic, if the item is in the first Box, filter it out,
//       // and put into the second, otherwise the other way around..
//       this.setState({
//           boxOne: isInBoxOne
//             ? this.state.boxOne.filter(i => i !== itemId)
//             : [ ...this.state.boxOne, itemId ],
//           boxTwo: isInBoxOne
//             ? [ ...this.state.boxTwo, itemId ]
//             : this.state.boxTwo.filter(i => i !== itemId)
//       });
//   }
//   render() {
//       return (
//           <div>
//               <ItemList handleEvent={this.handleEvent} items={this.state.boxOne} allItems={this.props.items} />
//               <ItemList handleEvent={this.handleEvent} items={this.state.boxTwo} allItems={this.props.items} />
//           </div>
//       );
//     }
//   };
  
//   var items = [
//     {name: "Item 1", id: 1},
//     {name: "Item 2", id: 2},
//     {name: "Item 3", id: 3},
//     {name: "Item 4", id: 4},
//   ]
  
//   ReactDOM.render(
//     // Pass the initial items to your App
//     <App items={items} />,
//     document.getElementById('root')
//   );