import React, { Component } from 'react'
import { Redirect } from "react-router-dom";

import api from '../api'
import styled from 'styled-components'

import * as QueryString from "query-string"

const Wrapper = styled.div.attrs({
    className: 'form-group',
})`
    margin: 0 30px;
`

class GrantAccessReturn extends Component {

    constructor(props) {
        super(props)
        this.state = {
            readyToRedirect: false
        }
    }

    componentDidMount = async () => {
        const params = QueryString.parse(this.props.location.search);
        console.log(params);
        
        await api.grant_access_return(params).then(returnedValue => {
            this.setState({ readyToRedirect: true })
        })
    }
    
     
    render() {
        if (this.state.readyToRedirect) {
            
            return (<Redirect to="/" />)
            
        }else{
            return (
             <Wrapper>
               
            
            </Wrapper>
        )}
    }
}

export default GrantAccessReturn
