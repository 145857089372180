import React, { Component } from 'react'
import api from '../api'
import styled from 'styled-components'

const Wrapper = styled.div.attrs({
    className: 'form-group',
})`
    margin: 0 30px;
`

const WowsButton = styled.a.attrs({
    className: `btn btn-primary`,
})`
    margin: 15px 15px 15px 5px;
`

class GetAllWGShips extends Component {
   

    handleWowsStuff = async () => {

        const payload = "TEST"
        console.log("***BUTTON CLICK CLIENT SIDE***")
        console.time("***TRIGGER FUNCTION FROM CLIENT SIDE*** :")

        await api.get_All_WG_Ships(payload).then(res => {
            window.alert(`Pulled list of all ships successfully`)

        })

        console.timeEnd("***TRIGGER FUNCTION FROM CLIENT SIDE*** :")
    }
    
    render() {
        return (
            <Wrapper>
               
                <WowsButton onClick={this.handleWowsStuff}>Get All Ships</WowsButton>
            </Wrapper>
        )
    }
}

export default GetAllWGShips
